import "./sass/main.scss";
//import "https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js";


  $('#carousel-example').on('slide.bs.carousel', function (e) {
    var $e = $(e.relatedTarget);
    var idx = $e.index();
    var totalItems = $('.carousel-item').length;
    var itemsPerSlide = 4; //Prev Cal : totalItems - 3

    if (idx >= totalItems-4) { //Prev Cal : idx >= totalItems-(itemsPerSlide-1)
        var it = itemsPerSlide - (totalItems - idx);
        for (var i=0; i<it; i++) {
            // append slides to end
            if (e.direction=="left") {
                $('.carousel-item').eq(i).appendTo('.carousel-inner');
            }
            else {
                $('.carousel-item').eq(0).appendTo('.carousel-inner');
            }
        }
    }
  });



function scrollPositionCheck() {
  if (window.scrollY < 10) {
    document.querySelector(".navbar").classList.remove("detached");
  } else {
    document.querySelector(".navbar").classList.add("detached");
  }
}

document.addEventListener(
  "DOMContentLoaded",
  function(event) {
    scrollPositionCheck();
  },
  false
);

window.addEventListener("scroll", function() {
  scrollPositionCheck();
});








function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);


function revealdown() {
  var reveald = document.querySelectorAll(".revealdown");

  for (var i = 0; i < reveald.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveald[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveald[i].classList.add("active");
    } else {
      reveald[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", revealdown);


function slideright() {
  var rslides = document.querySelectorAll(".slide-right");

  for (var i = 0; i < rslides.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = rslides[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      rslides[i].classList.add("active");
    } else {
      rslides[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", slideright);

function sliderightunder() {
  var rslides = document.querySelectorAll(".slide-right-under");

  for (var i = 0; i < rslides.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = rslides[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      rslides[i].classList.add("active");
    } else {
      rslides[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", sliderightunder);

function slideleft() {
  var lslides = document.querySelectorAll(".slide-left");

  for (var i = 0; i < lslides.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = lslides[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      lslides[i].classList.add("active");
    } else {
      lslides[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", slideleft);

function slideleftunder() {
  var lslides = document.querySelectorAll(".slide-left-under");

  for (var i = 0; i < lslides.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = lslides[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      lslides[i].classList.add("active");
    } else {
      lslides[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", slideleftunder);



$(document).ready(function(event){
  $('form input').click(function(event){
    $('form > div').css('transform', 'translateX('+$(this).data('location')+')');
    $(this).parent().siblings().removeClass('selected');
    $(this).parent().addClass('selected');
  });
});



var faq = document.getElementsByClassName("faq");
var i;
for (i = 0; i < faq.length; i++) {
  faq[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}



function change_period(period){
  var monthly = document.getElementById("monthly");
  var semester = document.getElementById("semester");
  var annual = document.getElementById("annual");
  if(period === "monthly"){
    semester.className +=  "switch semester";
    annual.className +=  "switch annual";
    setTimeout(function(){
      monthly.className +=  "switch monthly active";
    },500);
  }else if(period === "semester"){
    monthly.className +=  "switch monthly";
    annual.className +=  "switch annual";
    setTimeout(function(){
      semester.className +=  "switch semester active";
    },500);
  }else{
    monthly.className +=  "switch monthly";
    semester.className +=  "switch semester";
    setTimeout(function(){
      annual.className +=  "switch annual active";
    },500);
  }
}

function change_period2(period){
  var monthly = document.getElementById("monthly2");
  var semester = document.getElementById("semester2");
  var annual = document.getElementById("annual2");
  var selector = document.getElementById("selector");
  if(period === "monthly"){
    selector.style.left = 0;
    selector.style.width = monthly.clientWidth + "px";
    selector.style.backgroundColor = "#777777";
    selector.innerHTML = "Mensuel";
  }else if(period === "semester"){
    selector.style.left = monthly.clientWidth + "px";
    selector.style.width = semester.clientWidth + "px";
    selector.innerHTML = "Semestriel";
    selector.style.backgroundColor = "#418d92";
  }else{
    selector.style.left = monthly.clientWidth + semester.clientWidth + 1 + "px";
    selector.style.width = annual.clientWidth + "px";
    selector.innerHTML = "Annuel";
    selector.style.backgroundColor = "#4d7ea9";
  }
}